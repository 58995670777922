<template>
<div>
  <b-modal 
    id="bv-modal-userdata" 
    size="lg" 
    hide-footer
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
    @hide="close" 
    ref="modalUserdata"
  >
      <template v-slot:modal-title>
        {{ $t($i18n.locale + '.keys.modalUserdata.header') }}
      </template>
      <div class="innerContentWrapper">
        <div class="usernameWrapper blockWrapper" v-if="$store.getters.vitalQuestionsMissing & 32">
          <h4>{{ $t($i18n.locale + '.keys.modalUserdata.usernameLabel') }}</h4>
          <div class="usernameInputWrapper inputWrapper">
            <input
              type="text"
              placeholder=""
              v-model="userData.username"
            />
            <p v-if="formErrors.usernameError" class="dateInputError formError">
              {{ formErrors.usernameError }}
            </p>
          </div>
          <h4>{{ $t($i18n.locale + '.keys.modalUserdata.randomUsernamesLabel') }}</h4>
          <div class="randomUsernameWrapper">
            <div class="randomUsernameButtons">
              <button 
                class="randomUsernamesBtn" 
                v-for="(name, index) in randomUsernames" 
                :key="index"
                :class="{
                  'selected': userData.username == name
                }"
                @click.prevent="selectRandomUsername(index, name)"
              >
                <span>{{ name }}</span>
              </button>
            </div>
          </div>

        </div>
        <div class="birthdateWrapper blockWrapper" v-if="$store.getters.vitalQuestionsMissing & 64">
          <div class="birthdateInputWrapper inputWrapper">
            <h4>{{ $t($i18n.locale + '.keys.modalUserdata.birtdayLabel') }}</h4>
            <input
              :placeholder="dateInputPlaceholder"
              class="genericInput"
              v-model="birthdateInput"
              type="tel"
              minlength="10"
              maxlength="10"
              onPaste="return false"
              @input="handleDateInput"
            />
            <p v-if="formErrors.birthdateError" class="dateInputError formError">
              {{ formErrors.birthdateError }}
            </p>
          </div>
        </div>
        <div class="locationWrapper blockWrapper" v-if="$store.getters.vitalQuestionsMissing & 128">
          <h4>{{ $t($i18n.locale + '.keys.modalUserdata.locationLabel') }}</h4>
          <div class="locationPrefillWrapper" v-if="!locationEdit">
            <p>
              {{  userData.location.city }}, {{  userData.location.country }}
            </p>
            <button class="editButton" @click="locationEdit = !locationEdit">
              <i class="fa fa-pencil"></i> 
              <span>{{ $t($i18n.locale + '.keys.modalUserdata.editButton') }}</span>
            </button>
          </div>
          <div class="locationInputWrapper inputWrapper" v-if="locationEdit">
            
            
            <div class="locationInput">
              <vue-google-autocomplete
                :key="locationData.autocompleteKey"
                id="map"
                class="genericInput"
                ref="userLocation"
                :class="{ error: formErrors.locationError }"
                :placeholder="$t($i18n.locale).keys.questionnaire.type7.enterLocation"
                v-on:placechanged="getAddressData"
                @keyup="addressChange"
                :country="country"
                :types="types"
                :value="locationData.address"
                v-on:no-results-found="noResults"
                @focus="focusInput"
                :enable-geolocation=true
                :inputChange="addressChange"
              ></vue-google-autocomplete>
              <button class="editButton" @click="cancelLocationEdit">
                <i class="fa fa-times"></i> 
                <span>{{ $t($i18n.locale + '.keys.modalUserdata.cancelButton') }}</span>
              </button>
            </div>
            <p
              class="countryErrorMessage formError"
              v-if="formErrors.locationError"
            >
              <span>
                {{$t($i18n.locale).keys.questionnaire.type7.onlyCitiesFromYourCountry}} 
              </span>
              <span>({{$store.getters.getCountry.toUpperCase()}})</span>
              <span> 
                {{$t($i18n.locale).keys.questionnaire.type7.areAvailable}}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div class="actionButtons">
        <b-button
          class="my-3 sendButton"
          :disabled="!dataIsValid"
          block
          @click="sendData"
        >
        {{ $t($i18n.locale + '.keys.modalUserdata.continueButton') }} <i class="fa fa-chevron-right"></i>
        </b-button>
      </div>

    </b-modal>
</div>
</template>

<script>

import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  name: "ModalUserData",
  components: { VueGoogleAutocomplete },
  data() {
    return {
      url: process.env.VUE_APP_URL,
      target: '',
      userData: {
        username: this.$store.getters.user.username,
        birthdate: '',
        location: {
          country: this.$store.getters.user.location.country,
          city: this.$store.getters.user.location.city,
          coordinates: {
            latitude: this.$store.getters.user.location.lat,
            longitude: this.$store.getters.user.location.lng,
          },
          zip: this.$store.getters.user.location.zip,
        },
        
        //this.$store.getters.user.location,
      },
      formErrors: {
        usernameError: '',
        birthdateError: '',
        locationError: '',

      },
      randomUsernames: [],
      selectedIndex: null,
      birthdateInput: '',
      locationData: {
        address: "",
        city: "",
        country: "",
        inputTimeout: null,
        autocompleteKey: 1,
      },
      locationEdit: false,
    };
  },
  watch: 
  {
    locationEdit: async function (val) {
      if(val == true){
        await this.$nextTick();
        console.log('nextTick', this.$refs.userLocation)
        this.$refs.userLocation.geolocate();
      }else{
        /*
        this.userData.location = {
          country: null,
          city: null,
          lat: null,
          lng: null,
          zip: null,
        }
          */
      }
    },
    birthdateInput: function (val) {
      this.userData.birthdate = this.dateFormated(val)

      var validation = this.validateBirthdate(val, this.dateFormatByCountry)
      if(validation.error){
        console.log('answerInputError', validation.error)
        this.formErrors.birthdateError = this.$t(this.$i18n.locale+'.keys.questionnaire.type5.errorMinAge', {minAge: this.minAge});
      }else {
        this.formErrors.birthdateError = null;
      }
    },
    'userData.username': function (val) {
      if(val.length > 32) this.formErrors.usernameError = 'Username too long'
    },
    
  },
  computed: {
    
    minAge(){
      return 18;
    },
    minYear() {
      return (new Date().getFullYear() - this.minAge);
    },
    maxYear() {
      return (new Date().getFullYear() - 90);
    },

    dateFormatByCountry: function() {

      if(['de', 'at', 'ch', 'cz', 'pl'].includes(domain)){
        return 'dd.mm.yyyy';
      }else{
        return 'yyyy/mm/dd';
      }
    },
    dateInputPlaceholder: function() {
      if(this.dateFormatByCountry == 'dd.mm.yyyy' && this.$store.getters.selectedLanguage == 'de'){
        return 'tt.mm.jjjj';
      }else if(this.dateFormatByCountry == 'dd.mm.yyyy'){
        return 'dd.mm.yyyy';
      }else{
        return 'yyyy/mm/dd';
      }
    },

    types: function() {

      if(this.$store.getters.getCountry == 'us'){
        //this.locationData.autocompleteKey++;
        return '(regions)';
      } 
      else return '(cities)';
    },
    country: function() {
      return [this.$store.getters.getCountry];
    },
    dataIsValid() {
      if(
        //Username check
        (
          !(this.$store.getters.vitalQuestionsMissing & 32) ||
          (this.userData.username.length >= 2 && this.userData.username.length <= 32 && !this.formErrors.usernameError)
        ) &&
        //Birthdate check
        (
          !(this.$store.getters.vitalQuestionsMissing & 64) ||
          (this.userData.birthdate.length == 10 && !this.formErrors.birthdateError)
        ) &&
        //Location check
        (
          !(this.$store.getters.vitalQuestionsMissing & 128) ||
          ((this.userData.location.city && !this.formErrors.locationError) || !this.locationEdit)
        )
      ) {
          //All checks positive
          return true;        
      //One or more checks failed
      }else return false;
    }
  },
  mounted() {

    this.generateRandomUsernames()

  },
  methods: {
    sendData() {
      let data = {};
      if(this.$store.getters.vitalQuestionsMissing & 32) data.username = this.userData.username
      if(this.$store.getters.vitalQuestionsMissing & 64) data.birthday = this.userData.birthdate
      if(this.$store.getters.vitalQuestionsMissing & 128) data.location = this.userData.location

      console.log('sendData', data)

      const URL = this.url + '/api/personality/answer_vital';
      this.$axios.post(URL, data)
      .then(response => {
        console.log('response', response.data)

        //Trigger Finish Event 
        const userId = this.$store.getters.getAuthUser.user_id
        this.$store.dispatch('triggerFinishEvent', { userId: userId })

        this.$store.dispatch("generateMatches").then(() => {
          this.$store.dispatch("fetchMatches").then(()=>{
            this.$store.dispatch("filterMatches")
          });

          this.$store.dispatch('checkCompleted');
          this.$refs.modalUserdata.hide();
        });
        
      })
      .catch(err => {
        console.log(err)
      })

    },
    show(profile_id, target){
      this.profile_id = profile_id;
      if(typeof target != "undefined"){
        this.target = target;
      }
      this.$refs.modalUserdata.show();
    },
    close() {
      this.target = '';
    },

    //Username Functions
   generateRandomUsernames(count){

    if(typeof count == 'undefined') count = 3;
    const { uniqueNamesGenerator, adjectives, animals, NumberDictionary  } = require('unique-names-generator');

    

    for(var i=0; i<count; i++){
      const numberDictionary = NumberDictionary.generate({ min: 18, max: 99 });
      const randomName = uniqueNamesGenerator({ 
          dictionaries: [adjectives, animals, numberDictionary],
          separator: '',
          style: 'capital'
        });
      this.randomUsernames.push(randomName)
    }

   },

   selectRandomUsername(index, name){

    this.selectedIndex = index;
    this.userData.username = name;

   },

   verifyUsername(){
    let trimmedAnswer = this.userData.username.trim();
      if(
        this.verifyRegex.test(trimmedAnswer) &&
        trimmedAnswer.length >= 2 &&
        trimmedAnswer.length <= 32
        ){
          this.formErrors.usernameError = null
        return true
      }else{
        this.formErrors.usernameError = this.$t(this.$i18n.locale +'.keys.questionnaire.type2.usernameError')
        return false
      }
   },
  
   // Birthdate Functions

    dateFormated(dateString) {
      var formatedDate, dateArray;

        console.log("dateFormatted", dateString)

        if(this.dateFormatByCountry == 'dd.mm.yyyy'){
          dateArray = dateString.split('.')
          formatedDate = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;  
        }else{
          dateArray = dateString.split('/')
          formatedDate = `${dateArray[0]}-${dateArray[1]}-${dateArray[2]}`;  
        }

        console.log("formatedDate",formatedDate)

      
      return formatedDate;
    },
    handleDateInput(e){
      console.log("handleDateInput", e.target.value, e.inputType, e)
      if(e.inputType == 'deleteContentBackward') return true;

      var val = e.target.value.substring(0, e.target.selectionStart)

      var output = this.handleTyping(this.removeDateMask(val), this.dateFormatByCountry);

      console.log("handleTyping", output)

      this.birthdateInput = this.applyDateMask(output, this.dateFormatByCountry);

      return false;
      
    },

    handleTyping(e, t) {

      switch (t.replace(/[-/.]/gi, '')) {
      case 'yyyymmdd':
          return this.handleDateFormatAutocompleteYYYYMMDD(e);
      case 'ddmmyyyy':
          return this.handleDateFormatAutocompleteDDMMYYYY(e);
      default:
          return e
      }
    },
    applyDateMask(e, t) {
      for (var i = t.match('[-/.]')[0], r = [], n = 0; n < e.length; n++)
        r.push(e[n]),
        t.startsWith('yyyy') && (3 !== n && 5 !== n || r.push(i)),
        (t.startsWith('dd') || t.startsWith('mm')) && (1 !== n && 3 !== n || r.push(i));
      return r.join('')
    },
    removeDateMask(e) {
      return e.replace(/\D/g, '')
    },
    handleDateFormatAutocompleteDDMMYYYY (dateString) {

      console.log('handleDateFormatAutocompleteDDMMYYYY', dateString);

        let dayChar = dateString.charAt(0),
            monthAndYear = dateString.substring(1),
            monthChar = monthAndYear.charAt(0),
            yearChar = monthAndYear.charAt(1),
            yearString = monthAndYear.substring(1);

        if (1 === dateString.length) {
          if (3 < parseInt(dayChar, 10)) {
            dateString = '0'.concat(dateString);
          }
        }

        if (2 === dateString.length) {
          if (31 < parseInt(monthChar, 10)) {
            if (parseInt(yearChar, 10) < 2) {
              dateString = '0'.concat(dateString.charAt(0), dateString.charAt(1));
            } else {
              dateString = '0'.concat(dateString.charAt(0), '0', dateString.charAt(1));
            }
          }
        }

        if (3 === dateString.length) {
          yearChar = dateString.charAt(2);
          if (1 < parseInt(yearChar, 10)) {
            dateString = dateString.slice(0, -1).concat('0', yearChar);
          }
        }
        
        if (4 === dateString.length) {
          monthAndYear = dateString.substring(2, 4);
          if (12 < parseInt(monthAndYear, 10)) {
            yearChar = dateString.charAt(2);
            let yearDigit = dateString.charAt(3);
            dateString = dateString.slice(0, -2).concat('0', yearChar);
            if (9 === parseInt(yearDigit, 10)) {
              dateString = dateString.concat(yearDigit);
            } else if (parseInt(yearDigit, 10) < 9) {
              dateString = dateString.concat('19', yearDigit);
            }
          }
        }
        
        if (5 === dateString.length) {
          yearChar = dateString.charAt(4);
          if (0 === parseInt(yearChar, 10)) {
            dateString = dateString.slice(0, -1).concat('20', yearChar);
          } else if (2 < parseInt(yearChar, 10)) {
            dateString = dateString.slice(0, -1).concat('19', yearChar);
          }
        }
        
        if (6 === dateString.length) {
          yearString = dateString.substring(4, 6);
          let yearDigit = dateString.charAt(5);
          if (20 < parseInt(yearString, 10)) {
            dateString = dateString.slice(0, -2).concat('200', yearDigit);
          } else if (parseInt(yearString, 10) < 19) {
            dateString = dateString.slice(0, -2).concat('19', yearDigit);
          }
        }

        return dateString;
    }, 
    handleDateFormatAutocompleteYYYYMMDD (dateString) {

      console.log('handleDateFormatAutocompleteYYYYMMDD',dateString);

        var firstTwoChars, firstChar, secondChar, year, monthAndYear, yearString, yearDigit;
        if (1 === dateString.length) {
          firstChar = dateString.charAt(0);
          if (0 === parseInt(firstChar, 10)) {
            dateString = dateString.slice(0, -1).concat('20', firstChar);
          } else if (2 < parseInt(firstChar, 10)) {
            dateString = dateString.slice(0, -1).concat('19', firstChar);
          }
        } else if (2 === dateString.length) {
          firstTwoChars = dateString.substring(0, 2);
          secondChar = dateString.charAt(1);
          if (20 < parseInt(firstTwoChars, 10)) {
            dateString = dateString.slice(0, -2).concat('200', secondChar);
          } else if (parseInt(firstTwoChars, 10) < 19) {
            dateString = dateString.slice(0, -2).concat('19', secondChar);
          }

        } else if (5 === dateString.length) {
          year = dateString.charAt(4);
          if (1 < parseInt(year, 10)) {
            dateString = dateString.slice(0, -1).concat('0', year);
          }

        } else if (6 === dateString.length) {
          monthAndYear = dateString.substring(4, 6);
          if (12 < parseInt(monthAndYear, 10)) {
            year = dateString.charAt(4);
            yearDigit = dateString.charAt(5);
            dateString = dateString.slice(0, -2).concat('0', year);
            if (parseInt(yearDigit, 10) <= 3) {
              dateString = dateString.concat(yearDigit);
            } else if (3 < parseInt(yearDigit, 10)) {
              dateString = dateString.concat('0', yearDigit);
            }

          }

        } else if (7 === dateString.length) {
          yearString = dateString.charAt(6);
          if (3 < parseInt(yearString, 10)) {
            dateString = dateString.slice(0, -1).concat('0', yearString);
          }

        }

        return dateString;
      },
    validateBirthdate(birthdateString, dateFormat) {

      console.log("validateBirthdate", birthdateString.length, dateFormat)

      const dateParts = birthdateString.split(dateFormat.match('[-/.]')[0])
        .map(part => parseInt(part, 10));

        console.log("validateBirthdate", birthdateString, dateFormat, dateParts)

      if (birthdateString.length !== 10)
        return {
            success: false
        };

        let day = dateParts[0],
          month = dateParts[1] - 1,
          year = dateParts[2];

        if(dateFormat == 'yyyy/mm/dd'){
          day = dateParts[2]
          month = dateParts[1] - 1
          year = dateParts[0]
        }
      const birthdate = new Date(year, month, day);

      console.log("validateBirthdate", [day, month, year], birthdate)

      if (day < 1 || 31 < day)
          return {
              success: false,
              error: 'invalidDate 1'
          };
      if (month < 0 || 11 < month)
          return {
              success: false,
              error: 'invalidDate 2'
          };
        const minYearDate = (new Date).setFullYear(this.minYear)
        const maxYearDate = (new Date).setFullYear(this.maxYear)
        const minYear = new Date(minYearDate).getFullYear()
        const maxYear = new Date(maxYearDate).getFullYear()
      if (year < minYear || maxYear < year) {
        console.log('validateBirthdate', year, minYear, maxYear)
          if (year < maxYear)
              return {
                  success: false,
                  error: 'invalidMinYear - too old'
              };
          if (minYear < year)
              return {
                  success: false,
                  error: 'invalidMaxYear - too young'
              }
      } else if (year === maxYear) {
          const current = new Date();
          if (month > current.getMonth() + 1 || month === current.getMonth() + 1 && day > current.getDate())
              return {
                  success: false,
                  error: 'invalidAge 2'
              }
      }
      if (birthdate.getDate() === day && birthdate.getMonth() === month && birthdate.getFullYear() === year)
          return {
              success: true
      }
      return {
          success: false,
          error: 'invalidDate 3'
      }
    },

    //Location functions
    noResults: function(){
      console.log('NO RESULTS',$('.pac-container .pac-item').length);
      this.formErrors.locationError = true;
    },
    addressChange: function($event){
        console.log('addressChange', $event);
        console.log($event.target.value);
        const value = $event.target.value
        const skipKeys = [13, 38, 40]

        window.clearTimeout(this.inputTimeout)

        if(value != '' && !skipKeys.includes($event.keyCode)){
          this.$nextTick(()=>{
            this.inputTimeout = setTimeout(() => {
              //console.log('autocomplete visible TO',$('.pac-container').is(':visible'),$('.pac-container .pac-item').length)
              if(!$('.pac-container').is(':visible')){
                this.formErrors.locationError = true;
              }else{
                console.log('addressChange - showError')
                this.formErrors.locationError = false;
              }
            }, 500);

          })

        }else {
          this.formErrors.locationError = false;
        }
    },
    getAddressData: function(addressData, placeResult) {
     console.log(placeResult, 'place results');
     console.log(addressData, 'addressData');
      this.address = addressData;
      if(!this.address.locality && !placeResult.name){
        this.$refs.userLocation.clear();
        this.userData.location = this.$store.getters.user.location
        return false;
      }
      this.$refs.userLocation.blur();
      this.userData.location = {
        country: this.address.country,
        city: (this.address.locality) ? this.address.locality : placeResult.name,
        coordinates: {
          latitude: this.address.latitude,
          longitude: this.address.longitude,
        },
        zip: this.address.postal_code,
      };
      // console.log(placeResult['address_components'], 'short');

      let returnedData = placeResult['address_components'];
      let countryData = returnedData.filter(item =>
        item.types[0] == 'country' || item.types[1] == 'country'
      )

      countryData[0]['short_name'] == 'GB' ? countryData[0]['short_name'] = 'UK' : '';
      if(countryData[0]['short_name'] == this.$store.getters.getCountry.toUpperCase()) {
        this.formErrors.locationError = false;
      } else {
        this.formErrors.locationError = true;
      }
    },
    focusInput(){
      console.log('focus Input');
      this.$refs.userLocation.clear();
      this.userData.location = this.$store.getters.user.location
    },

    cancelLocationEdit(){
      this.$refs.userLocation.clear();
      this.userData.location = {
          country: this.$store.getters.user.location.country,
          city: this.$store.getters.user.location.city,
          coordinates: {
            latitude: this.$store.getters.user.location.lat,
            longitude: this.$store.getters.user.location.lng,
          },
          zip: this.$store.getters.user.location.zip,
        }
      this.locationEdit = !this.locationEdit
    },

  }

};
</script>

<style lang="scss" scoped>

</style>
