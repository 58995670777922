<template>
  <div id="membershipMatchesList" class="container-lg">
    <div id="matchesSearch" class="">
      <MatchesSearchBar />
       <MatchesFilters v-if="isMobile"  @filterResetList="filterResetList"/>
    </div>
    <div class=" matchesContainer">
      <div class="leftContainer">
        <MatchesToPreferences v-if="!isMobile" />
        <MatchesFilters v-if="!isMobile"  @filterResetList="filterResetList"/>
      </div>
      <div class="rightContainer" v-if="showMatches">
        <MatchesList @endOfList="endOfMatches" />
        <h1 id="altMatchesHeadline" v-if="(showAltMatches || allMatches.length == 0) && allAlternativeMatches.length != 0">
          <span v-if="allMatches.length == 0">
            {{ $t($i18n.locale + '.keys.membershipArea.membershipMatches.alternativeMatches.noMatchesHl') }}
          </span>
          <span v-else>
            {{ $t($i18n.locale + '.keys.membershipArea.membershipMatches.alternativeMatches.fewMatchesHl') }}
          </span>
        </h1>
        <AltMatchesList v-if="(showAltMatches || allMatches.length == 0) && allAlternativeMatches.length != 0" :showAltMatches="showAltMatches" />
        <MatchesToPreferences v-if="isMobile" />
      </div>
    </div>
    <b-modal id="welcome-modal" hide-footer hide-header @hidden="hideWelcomeModal" size="lg">
    <!-- <img class="headerImage" :src="require('@/assets/images/' + $store.getters.assetsPath + 'welcome_header.jpg" /> -->
    <div class="header">
      <h1>{{ $t($i18n.locale + '.keys.welcomeModal.mainHeadline',{productName: $store.getters.productName,}) }}</h1>
    </div>
    <div class="content">
      <img class="mobileImage" :src="require('@/assets/images/' + $store.getters.assetsPath + 'welcome_header_bg.jpg')" />
      <h3>{{ $t($i18n.locale + '.keys.welcomeModal.subHeadline') }}</h3>

      <div class="checklist">
        <div class="checklist-item">
          <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'check-green.svg')" />
          <span>
          {{ $t($i18n.locale + '.keys.welcomeModal.checklist_1') }}
          </span>
        </div>
        <div class="checklist-item">
          <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'check-green.svg')" />
          <span>
          {{ $t($i18n.locale + '.keys.welcomeModal.checklist_2') }}
          </span>
        </div>
        <div class="checklist-item">
          <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'check-green.svg')" />
          <span>
          {{ $t($i18n.locale + '.keys.welcomeModal.checklist_3') }}
          </span>
        </div>
      </div>
      <div class="bottom">
      {{ $t($i18n.locale + '.keys.welcomeModal.bottomInfo_1') }}
      <br><br>
      {{ $t($i18n.locale + '.keys.welcomeModal.bottomInfo_2') }}
      </div>
      <div class="buttonWrapper">
        <b-button variant="primary" class="my-3" @click="hideWelcomeModal">
          {{ $t($i18n.locale + '.keys.welcomeModal.button') }}
        </b-button>
      </div>
    </div>



    </b-modal>

  </div>
</template>

<script>
//import MembershipMisc from "@/components/membership-area/MembershipMisc.vue";
import MatchesSearchBar from "@/components/membership-area/membership-matches/MatchesSearchBar.vue";
import MatchesToPreferences from "@/components/membership-area/membership-matches/MatchesToPreferences.vue";
import MatchesFilters from "@/components/membership-area/membership-matches/MatchesFilters.vue";
import MatchesList from "@/components/membership-area/membership-matches/MatchesList.vue";
import AltMatchesList from "@/components/membership-area/membership-matches/AltMatchesList.vue";



import { mapGetters } from "vuex";
import jwt from "jsonwebtoken";

export default {
  name: "MembershipMatchesList",
  components: {
    //MembershipMisc,
    MatchesSearchBar,
    MatchesToPreferences,
    MatchesFilters,
    MatchesList,
    AltMatchesList,
  },
  data() {
    return {
      show1: false,
      searchBoxOffset: 0,
      showAltMatches: false,
      showMatches: false,
      userId: 0,
      matchesLoading: false,
      matchesLoadData: {
        offset: 0,
        limit: 10
      }
    };
  },
  computed: {
    ...mapGetters([
      "allFilteredMatches",
      "allAlternativeMatches",
      "allMatches",
      "isMobile"
    ]),
  },
  mounted() {

    //this.$refs.modalQuestionaire.show();

    if (this.$route.query.reg == 1 && !this.$store.getters.vitalQuestionsMissing) {

        let token = localStorage.getItem("token");
        let decoded = jwt.decode(token, { complete: true });
        if(decoded){
          this.userId = decoded.payload.user_id;
        }
        console.log('SHOW WELCOME MODAL');
        //Show welcome modal after Userdata modal
        //this.$bvModal.show('welcome-modal');
        
console.log("modalUserdata vitalQuestionsMissing", this.$store.getters.vitalQuestionsMissing);

        //Send finish event and trigger all tracking scripts
        this.$store.dispatch("triggerFinishEvent", {
          userId: this.userId
        });

        //FALLBACK for match generation (needs allMatches first)
        /*
        this.$store.dispatch("fetchMatches").then(res => {
          if(!this.allMatches.length) {
            this.$store.dispatch("generateMatches").then(res => {
              this.$store.dispatch("fetchMatches");
            });
          }
        });
        */

       //remove the query
       //this.$router.replace({'query': null});
      }

    //remove the query
    this.$router.replace({'query': null});

    window.addEventListener('scroll', this.scrollHandler);

    window.scrollTo(0,0);

    this.$store.dispatch("fetchMatches").then(() => {
      console.log('fetchMAtches done')
      this.$store.dispatch("filterMatches", {
        ageFrom: 0,
        ageTo: 500,
        heightFrom: 0,
        heightTo: 500,
        newFilter: 0,
        photoFilter: 0,
        favouritesFilter: 0,
        contactFilter: 0,
        distanceFilter: 5000
      })
      this.$store.dispatch("showContent");
      this.$store.dispatch("hideLoader").then(()=>{
          //this.stickyHeadline();
          this.showMatches = true;
          if(this.allFilteredMatches.length == 0) {
            this.scrollHandler(false)
          }
          if(this.allAlternativeMatches.length) {
            this.showAltMatches = true;
          }

      });
    });

},
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandler);
  },
  methods: {
    filterResetList(){
      window.addEventListener('scroll', this.scrollHandler)
      //this.endOfMatches(false)
    },
    scrollHandler() {
      //console.log('scrollHandler Matches', evt); 

      var loadOffset = 100;
      let scrollDiff = (document.documentElement.scrollHeight - window.innerHeight) - window.scrollY;
      //var beforeScrollHeight = document.documentElement.scrollHeight;

      //console.log('scrollHandler scrollDiff',  scrollDiff, beforeScrollHeight);

      if(scrollDiff < loadOffset && !this.matchesLoading){

        console.log('scrollHandler DO SOMETHING');
        this.matchesLoading = true;
        this.matchesLoadData.offset = this.allMatches.length;

        var filteredMatchesCount = this.allFilteredMatches.length;

        console.log('scrollHandler offset', this.matchesLoadData.offset);
        this.$store.dispatch("fetchMatches", this.matchesLoadData).then(res => {

          if(res == 'error') return false;

          this.$store.dispatch("filterMatches", this.$store.getters.getActiveMatchesFilters);
          console.log('scrollHandler fetchMatches', res);
          console.log('scrollHandler allFilteredMatches', filteredMatchesCount, this.allFilteredMatches.length);
          let filteredDiff = this.allFilteredMatches.length - filteredMatchesCount;

          if(res.matches.length == 0 || filteredDiff == 0){
            //this.$emit('endOfList', true);
            this.endOfMatches(true);
            //window.removeEventListener('scroll', this.scrollHandler);
            //if(this.allFilteredMatches.length) window.scrollTo(0, beforeScrollHeight)
          }
          this.matchesLoading = false;

        });

      }

    },
    stickyHeadline(){

      this.searchBoxOffset = $('#matchesSearch').offset().top - ($('#stickySection').height() + 20);

      window.addEventListener('scroll', function() {
        var searchBox = $('#matchesSearch');
        var matchList = $('.matchesContainer');
        var banner = $('.bannerWrapper').is(':visible') ? $('.bannerWrapper') : $('');

        if (window.pageYOffset > this.searchBoxOffset) {
          searchBox
            .addClass('sticky')
            .css({
              position:'fixed',
              top: $('#stickySection').height() + 'px',
              left:0,
              zIndex: 999,
              padding: 0,
            });
            matchList.css({
              paddingTop: $('#matchesSearch').height()
            });
            banner.css({
              marginTop: $('#matchesSearch').height()+20
            });
        } else {
          searchBox
            .removeClass('sticky')
            .css({
              position:'relative',
              top: 'auto',
              padding: ""
            });
            matchList.css({
              paddingTop: 0
            });
            banner.css({
              marginTop: 20
            });
        }

      }.bind(this));
    },
    endOfMatches(value){
      this.showAltMatches = value;
    },
    hideWelcomeModal() {
      const params = new URLSearchParams(window.location.search);
      params.delete('reg');
      if(Array.from(params).length > 0) window.history.replaceState({}, "", decodeURIComponent(`${window.location.pathname}?${params}`));
      else window.history.replaceState({}, "", decodeURIComponent(`${window.location.pathname}`));
      this.$bvModal.hide('welcome-modal');
    },

  }
};
</script>

<style lang="scss" scoped>

</style>